const createPrompt = (topic) => {
    return `
        Write a professional, SEO-optimized article for my website rezbuddy.ca on the topic **${topic}**. The article must be structured in a strict format to ensure consistent sections and formatting. Use at least 2 reputable sources and cite them correctly. It must also be a minimum of 1500 words. The response must be in **Markdown** and follow the exact structure below:

        **1. Title**: (Provide an engaging title between 5-10 words).
        **2. Short Description**: (A summary of 150-200 characters summarizing the article).
        ---
        **3. Full Article**: (minimum 1500 words long) The article, formatted in **Markdown** with appropriate headings and paragraphs:
            - Use \`#\` for the main title, \`##\` for section headings, and \`###\` for subheadings.
            - Use standard Markdown formatting for paragraphs and lists.
            - Keep the content structured for SEO and readability. Bring value to the readers.
         ---
        **4. References**: (Provide a minimum of 2 references in Markdown list format. Each reference must be accurately linked and credible).
        
        **Formatting Notes**:
        - Use pure **Markdown** without HTML tags.
        - **Include only the content in the format specified above, without any additional messages.**
        - Make sure the total word count is at least 1500 words but not more than 2500.
        - Remember to do research and include your references

        Ensure that the labels **Title**, **Short Description**, **Full Article**, and **References** are clearly separated and exactly match the format provided.


    `;
};

export default createPrompt;
