import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./EditArticles.scss";

const parseArticle = (articleText) => {
    const titleMatch = articleText.match(/(?<=\*\*Title\*\*:\s*)(.+)/i);
    const shortDescriptionMatch = articleText.match(/(?<=\*\*Short\s*Description\*\*:\s*)(.+)/i);
    const fullArticleMatch = articleText.match(
        /(?<=\*\*Full\s*Article\*\*:\s*)([\s\S]*?)(?=\*\*References\*\*)/i
    );

    return {
        title: titleMatch ? titleMatch[0].trim() : "",
        shortDescription: shortDescriptionMatch ? shortDescriptionMatch[0].trim() : "",
        fullArticle: fullArticleMatch ? fullArticleMatch[0].trim() : articleText.trim(),
    };
};

function EditArticle() {
    const location = useLocation();
    const navigate = useNavigate();
    const { article } = location.state;

    // Parse article content
    const initialArticle = parseArticle(article);
    const [title, setTitle] = useState(initialArticle.title || "");
    const [shortDescription, setShortDescription] = useState(initialArticle.shortDescription || "");
    const [fullArticle, setFullArticle] = useState(initialArticle.fullArticle || "");
    const [imageUrl, setImageUrl] = useState(article.imageUrl || "");

    const handleSubmit = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/articles/save`,
                { title, shortDescription, fullArticle, imageUrl },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (response.status === 200) {
                alert("Article saved successfully!");
                navigate("/articles");
            }
        } catch (error) {
            console.error("Error saving article:", error);
            alert("Error saving the article. Please try again.");
        }
    };

    return (
        <section className="main article-edit">
            <h1 className="article-edit__header">Edit Article</h1>
            <input
                className="article-edit__pic-input"
                placeholder="Enter pic URL here"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
            />
            <p>Title:</p>
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="article-edit__title-input"
            />
            <p>Short Description:</p>
            <input
                type="text"
                value={shortDescription}
                onChange={(e) => setShortDescription(e.target.value)}
                className="article-edit__short-desc-input"
            />
            <p>Full Article (Markdown):</p>
            <textarea
                value={fullArticle}
                onChange={(e) => setFullArticle(e.target.value)}
                rows="40"
                cols="100"
                className="article-edit__text-area"
            />
            <button className="article-edit__button" onClick={handleSubmit}>
                Save
            </button>
        </section>
    );
}

export default EditArticle;
