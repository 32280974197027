import React from "react";
import "./ArticleCard.scss";
import { Link, useNavigate } from "react-router-dom";

function ArticleCard({ article }) {
    const createSlug = (title) => {
        return title
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");
    };

    return (
        <>
            <Link to={`/articles/${createSlug(article.title)}`} state={{ article }}>
                <article className="blog-container">
                    <img
                        className="blog-container__image"
                        src={`${article.image_url}?w=600&h=400&q=70`}
                        srcSet={`
                                ${article.image_url}?w=300&h=200&q=70 300w,
                                ${article.image_url}?w=600&h=400&q=70 600w,
                                ${article.image_url}?w=1200&h=800&q=70 1200w
                                 `}
                        sizes="(max-width: 600px) 300px, (max-width: 1200px) 600px, 1200px"
                        alt={article.short_description}
                        loading="lazy"
                    />
                    <h2 className="blog-container__title">{article.title}</h2>
                    <p className="blog-container__body">{article.short_description}</p>
                    <Link
                        className="blog-container__link"
                        to={`/articles/${createSlug(article.title)}`}
                        state={{ article }}
                    >
                        Read The Full Article
                    </Link>
                </article>
            </Link>
        </>
    );
}

export default ArticleCard;
