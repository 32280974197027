import React from "react";
import { Link } from "react-router-dom";

function Disclaimer() {
    return (
        <section className="about-container__div">
            <p className="about-container__bullet-title">Disclaimer</p>
            <p className="about-container__sub-bod">
                We strive to provide the most accurate and up-to-date information on First Nation
                business locations and services across Canada, including gas stations and fuel
                prices. Our data is gathered from a collaborative effort that includes the
                Government of Canada’s First Nation Business Directory and contributions from our
                valued users and community members. However, due to the dynamic nature of business
                operations and fuel prices, there may be instances where the information provided
                does not reflect the current situation. We encourage our users to help enhance the
                accuracy and completeness of our data. If you encounter any discrepancies, outdated
                information, or missing locations—whether they are gas stations or other First
                Nation businesses—please do not hesitate to
                <Link to="/contact-us" className="about-container__link">
                    contact us
                </Link>
                . Your feedback is invaluable in helping us make the RezBuddy is as reliable and
                useful as possible.
            </p>
        </section>
    );
}

export default Disclaimer;
