import React, { useEffect, useState } from "react";
import "./Articles.scss";
import ArticleCard from "../../Components/ArticleCard/ArticleCard";
import { Helmet } from "react-helmet-async";
import ResourcesAPI from "../../Utils/ResourcesAPI";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import createPrompt from "../../Utils/ArticlePrompt";

function Articles() {
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();
    const [topic, setTopic] = useState("");
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const userDetails = await ResourcesAPI.getUserDetails();
                setIsAdmin(userDetails.isAdmin);
            } catch (error) {
                setIsAdmin(false);
            }
        };

        checkAdminStatus();
    }, []);

    const handleCreateArticle = async () => {
        try {
            // Use the topic value to create the prompt
            const userPrompt = createPrompt(topic);

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/articles/create`,
                { userPrompt }
            );

            const generatedArticle = response.data.message;

            // Navigate to the edit-article page, passing the generated article as state
            navigate("/edit-article", { state: { article: generatedArticle } });
        } catch (error) {
            console.error("Error generating article:", error);
        }
    };

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/articles`
                );
                setArticles(response.data.articles);
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <>
            <Helmet>
                <title>Indigenous Businesses Blog | RezBuddy</title>
                <meta
                    name="description"
                    content="Search for Indigenous businesses and explore articles about First Nation communities, economic development, and local businesses across Canada. Discover phone numbers, websites, and detailed descriptions of unique Indigenous-owned enterprises."
                />
                <meta
                    name="keywords"
                    content="Indigenous businesses, First Nation businesses, Canada, economic development, Indigenous-owned, local businesses, First Nation gas stations, Indigenous communities, support Indigenous, Indigenous blogs"
                />
                <meta name="author" content="RezBuddy.ca" />
                <meta property="og:title" content="Indigenous Businesses Blog" />
                <meta
                    property="og:description"
                    content="Explore and learn about Indigenous businesses and First Nation communities in Canada, including phone numbers, websites, and descriptions of local enterprises."
                />
                <meta property="og:url" content="https://rezbuddy.ca/articles" />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content="https://rezbuddy.ca/images/indigenous-businesses-blog.png"
                />
                <link rel="canonical" href="https://rezbuddy.ca/articles" />
            </Helmet>
            <main className="main">
                {isAdmin && (
                    <div className="article-add">
                        <input
                            className="article-add__input"
                            type="text"
                            value={topic}
                            placeholder="Enter topic here"
                            onChange={(e) => setTopic(e.target.value)}
                        />
                        <button onClick={handleCreateArticle} className="article-add__button">
                            Create Article
                        </button>
                    </div>
                )}
                <section className="articles-intro">
                    <h1 className="articles-intro__header">Our Articles</h1>
                    <p className="articles-intro__body">
                        Welcome to the RezBuddy Articles page! Here, you'll find a growing
                        collection of informative and insightful pieces dedicated to highlighting
                        Indigenous businesses and their vital role in the economy, community
                        development, and sustainable practices across Canada. Our mission is to
                        connect travelers, consumers, and supporters with First Nation enterprises
                        and provide an in-depth look into the stories, traditions, and contributions
                        of Indigenous communities.
                    </p>

                    <p className="articles-intro__body">
                        Whether you’re seeking travel advice, interested in discovering
                        Indigenous-owned services, or looking to stay informed about economic
                        developments, our articles aim to serve as your trusted source for authentic
                        information.
                    </p>

                    <p className="articles-intro__body">
                        Start exploring our articles today and engage with the stories and
                        businesses that shape the landscape of Indigenous communities.
                    </p>
                </section>
                <section className="articles-list">
                    {articles.length > 0 ? (
                        articles.map((article) => (
                            <ArticleCard key={article.id} article={article} />
                        ))
                    ) : (
                        <p className="homepage-articles__none">Loading...</p>
                    )}
                </section>
            </main>
        </>
    );
}

export default Articles;
