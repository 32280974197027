import "./Hero.scss";
import React from "react";

function Hero() {
    return (
        <section className="hero-container">
            <link
                rel="preload"
                href="https://res.cloudinary.com/dcydlrusb/image/upload/f_auto,q_auto/o6lyjsqp2xoxetiz44ab"
                as="image"
            />
            <link
                rel="preload"
                href="https://res.cloudinary.com/dcydlrusb/image/upload/f_auto,q_auto/qmlhbdr3v50yonyoq0bh"
                as="image"
            />

            <img
                src="https://res.cloudinary.com/dcydlrusb/image/upload/f_auto,q_auto/o6lyjsqp2xoxetiz44ab"
                alt="Map of Canada"
                className="hero-container__map"
            />
            <img
                src="https://res.cloudinary.com/dcydlrusb/image/upload/f_auto,q_auto/qmlhbdr3v50yonyoq0bh"
                alt="road trip car going to gas station"
                className="hero-container__roadie"
            />
        </section>
    );
}

export default Hero;
