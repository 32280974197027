import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";
import "./ArticleFull.scss";
import axios from "axios";

function FullArticle() {
    const location = useLocation();
    const navigate = useNavigate();
    const { slug } = useParams();
    const [article, setArticle] = useState(location.state ? location.state.article : null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!article) {
            const fetchArticle = async () => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/articles/${slug}`
                    );
                    setArticle(response.data.article);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching article:", error);
                    navigate("/404");
                }
            };

            fetchArticle();
        } else {
            setLoading(false);
        }
    }, [article, slug, navigate]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const ScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!article) {
        return <p>Error: Article not found</p>;
    }

    const articleSnippet = article.full_article.split(" ").slice(0, 25).join(" ") + "...";

    const keywords = [
        article.title,
        "Indigenous businesses",
        "Indigenous business",
        "RezBuddy",
        "First Nations",
        "Indigenous economic development",
        "Indigenous-owned gas stations",
        "support Indigenous businesses",
        "sustainable travel",
        "Canadian road trips",
        "Indigenous tourism",
        "Indigenous blog",
        "Indigenous articles",
    ];

    return (
        <>
            <Helmet>
                <title>{article.title} | RezBuddy</title>
                <meta name="description" content={article.short_description} />
                <meta name="keywords" content={keywords.join(", ")} />
                <meta property="og:title" content={article.title} />
                <meta property="og:description" content={articleSnippet} />
                <meta property="og:image" content={article.image_url} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <main className="main">
                <button onClick={handleGoBack} className="business-details-back">
                    Back
                </button>
                <section className="article">
                    <img className="article__image" src={article.image_url} alt={article.title} />
                    <h1>{article.title}</h1>
                    <h2 className="description">{article.short_description}</h2>
                    <ReactMarkdown className="full-article-content">
                        {article.full_article}
                    </ReactMarkdown>
                </section>
                <button onClick={ScrollToTop} className="business-details-back">
                    Scroll to top
                </button>
            </main>
        </>
    );
}

export default FullArticle;
