import React, { useState, useEffect, useRef } from "react";
import "./Businesses.scss";
import { useNavigate, useParams } from "react-router-dom";
import ResourcesApi from "../../Utils/ResourcesAPI";
import SearchBar from "../../Components/SearchBar/SearchBar";
import AlphabetList from "../../Components/AlphabetList/AlphabetList";
import BusinessList from "../../Components/BusinessList/BusinessList";
import Pagination from "../../Components/Pagination/Pagination";
import { Helmet } from "react-helmet-async";

function Businesses() {
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [currentLetter, setCurrentLetter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const totalResultsRef = useRef(0);
    const totalPagesRef = useRef(0);
    const { category, id, letter } = useParams();
    const navigate = useNavigate();

    const fetchInitialBusinesses = async () => {
        setIsLoading(true);
        try {
            const data = await ResourcesApi.getInitialBusinesses();
            setResults(data || []);
            totalResultsRef.current = data.total || 0;
            totalPagesRef.current = Math.ceil(totalResultsRef.current / itemsPerPage);
        } catch (error) {
            console.error("Error fetching initial businesses:", error);
            setResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchBusinessesByCategory = async (category, page = 1) => {
        setIsLoading(true);
        try {
            const data = await ResourcesApi.getBusinessesByCategory(category, page, itemsPerPage);
            setResults(data.businesses || []);
            totalResultsRef.current = data.total || 0;
            totalPagesRef.current = Math.ceil(totalResultsRef.current / itemsPerPage);
        } catch (error) {
            console.error("Error fetching businesses by category:", error);
            setResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchBusinessesByLetter = async (letter, page = 1) => {
        setIsLoading(true);
        try {
            const data = await ResourcesApi.getBusinessesByLetter(letter, page, itemsPerPage);
            setResults(data.businesses || []);
            totalResultsRef.current = data.total || 0;
            totalPagesRef.current = Math.ceil(totalResultsRef.current / itemsPerPage);
        } catch (error) {
            console.error("Error fetching businesses by letter:", error);
            setResults([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchBusinessById = async (businessId) => {
        setIsLoading(true);
        try {
            const data = await ResourcesApi.getBusinessById(businessId);
            setSelectedBusiness(data || null);
        } catch (error) {
            console.error("Error fetching business by ID:", error);
            setSelectedBusiness(null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!letter && !category && !id) {
            fetchInitialBusinesses();
        } else {
            setResults([]);
        }
    }, [letter, category, id]);

    useEffect(() => {
        if (letter) {
            fetchBusinessesByLetter(letter, currentPage);
        }
    }, [letter, currentPage]);

    useEffect(() => {
        if (category) {
            fetchBusinessesByCategory(category, currentPage);
        } else if (id) {
            fetchBusinessById(id);
        }
    }, [category, id, currentPage]);

    const handleCurrentLetter = (letter) => {
        setCurrentLetter(letter);
        setCurrentPage(1);
        setSelectedBusiness(null);
        navigate(`/indigenous-businesses/businesses/${letter}`);
    };

    const handleSelectBusiness = (business) => {
        setSearchTerm(business.company_operating_name);
        setSelectedBusiness(business);
        navigate(`/indigenous-businesses/${business.id}`);
    };

    const handleNextPage = () => {
        if (currentPage * itemsPerPage < totalResultsRef.current) {
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handleLastPage = () => {
        setCurrentPage(totalPagesRef.current);
    };

    const currentResults = selectedBusiness ? [selectedBusiness] : results;

    return (
        <>
            <Helmet>
                <title>Find Indigenous Businesses Across Canada | RezBuddy</title>
                <meta
                    name="description"
                    content="Discover and support Indigenous businesses across Canada with RezBuddy. Find First Nation businesses by name, phone number, website, and location. Learn more about local Indigenous-owned businesses near you."
                />
                <meta
                    name="keywords"
                    content="Indigenous businesses, First Nation businesses, support Indigenous, Canada, Indigenous economic development, local businesses, First Nation gas stations, Indigenous communities"
                />
                <meta name="author" content="RezBuddy.ca" />

                <meta
                    property="og:title"
                    content="Find Indigenous Businesses Across Canada | RezBuddy"
                />
                <meta
                    property="og:description"
                    content="Search Indigenous businesses by name, phone number, and website. Explore and support First Nation businesses with RezBuddy."
                />
                <meta property="og:url" content="https://rezbuddy.ca/indigenous-businesses" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="Find Indigenous Businesses Across Canada | RezBuddy"
                />
                <meta
                    name="twitter:description"
                    content="Explore Indigenous-owned businesses across Canada. Get details like phone numbers, websites, and descriptions with RezBuddy."
                />
                <link rel="canonical" href="https://rezbuddy.ca/indigenous-businesses" />
            </Helmet>
            <main className="main">
                <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    results={results}
                    setResults={setResults}
                    handleSelect={handleSelectBusiness}
                />
                <AlphabetList
                    currentLetter={currentLetter}
                    handleCurrentLetter={handleCurrentLetter}
                />
                <section className="bp-display-container">
                    <BusinessList businesses={currentResults} isLoading={isLoading} />
                    {!selectedBusiness && (
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPagesRef.current}
                            handleFirstPage={handleFirstPage}
                            handleLastPage={handleLastPage}
                            handleNextPage={handleNextPage}
                            handlePreviousPage={handlePreviousPage}
                        />
                    )}
                </section>
            </main>
        </>
    );
}

export default Businesses;
