import "./UpdateBiz.scss";
import React, { useState, useRef, useEffect } from "react";
import ResourcesApi from "../../Utils/ResourcesAPI";
import UpdateAddSearch from "../../Components/UpdateAddSearch/UpdateAddSearch";
import UpdateAddType from "../../Components/UpdateAddType/UpdateAddType";
import UpdateAddForm from "../../Components/UpdateAddForm/UpdateAddForm";
import { useNavigate } from "react-router-dom";

function UpdateBiz() {
    const [mode, setMode] = useState("");
    const [businessType, setBusinessType] = useState("");
    const [selectedBusiness, setSelectedBusiness] = useState({});
    const [searchTerm, setSearchTerm] = useState("");
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [addType, setAddType] = useState("");
    const searchRef = useRef(null);
    const [error, setError] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const userDetails = await ResourcesApi.getUserDetails();
                setIsAdmin(userDetails.isAdmin);
            } catch (error) {
                console.error("Error checking admin status:", error);
                setTimeout(() => {
                    navigate("/");
                }, 4000);
            }
        };

        checkAdminStatus();
    }, []);

    const handleModeChange = (e) => {
        setMode(e.target.value);
        setSelectedBusiness({});
    };

    const handleBusinessTypeChange = (e) => {
        setBusinessType(e.target.value);
        setSelectedBusiness({});
        setSearchTerm("");
        setResults([]);
    };

    const fetchBusinesses = async (term) => {
        try {
            let response;
            if (businessType === "indigenous_business") {
                response = await ResourcesApi.searchBusinesses(term);
            } else if (businessType === "gas_station") {
                response = await ResourcesApi.searchGasStations(term);
            }
            setResults(response || []);
            setShowDropdown(true);
        } catch (error) {
            console.error("Error fetching data:", error);
            setResults([]);
        }
    };

    const handleSearchTermChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        if (term) {
            fetchBusinesses(term);
        } else {
            setResults([]);
            setShowDropdown(false);
        }
    };

    const handleSelect = (business) => {
        setShowDropdown(false);
        if (businessType === "indigenous_business") {
            setSelectedBusiness({
                id: business.id,
                companyOperatingName: business.company_operating_name || "",
                companyLegalName: business.company_legal_name || "",
                email: business.email || "",
                phoneNumber: business.phone_number || "",
                url: business.url || "",
                city: business.city || "",
                province: business.province || "",
                address1: business.address1 || "",
                address2: business.address2 || "",
                postalCode: business.postal_code || "",
                companyDescription: business.company_description || "",
                featured: business.featured || false,
                category: business.category || "",
            });
        } else if (businessType === "gas_station") {
            setSelectedBusiness({
                ID: business.ID,
                name: business.Name || "",
                address: business.Address || "",
                city: business.City || "",
                province: business.Province || "",
                phone: business.Phone || "",
                location: {
                    lat: business.Location?.Lat || "",
                    long: business.Location?.Long || "",
                },
            });
        }
        setSearchTerm(business.company_operating_name || business.Name);
    };

    const handleDelete = async () => {
        const isConfirmed = window.confirm("Are you sure you want to delete this business?");
        if (!isConfirmed) {
            return;
        }

        try {
            if (businessType === "indigenous_business") {
                await ResourcesApi.deleteIndigenousBusiness(selectedBusiness.id);
            } else if (businessType === "gas_station") {
                await ResourcesApi.deleteGasStation(selectedBusiness.ID);
            }
            setSelectedBusiness({});
            setMode("");
            setSearchTerm("");
            setResults([]);
            setShowDropdown(false);
        } catch (error) {
            setError(error.response?.data?.message || "An error occurred. Please try again.");
            window.scrollTo(0, 0);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    const handleExit = () => {
        setShowDropdown(false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError("");

        let business = {};

        if (addType === "indigenous_business" || businessType === "indigenous_business") {
            business = {
                companyOperatingName: selectedBusiness.companyOperatingName,
                companyLegalName: selectedBusiness.companyLegalName,
                email: selectedBusiness.email,
                phoneNumber: selectedBusiness.phoneNumber,
                url: selectedBusiness.url,
                city: selectedBusiness.city,
                province: selectedBusiness.province,
                address1: selectedBusiness.address1,
                address2: selectedBusiness.address2,
                postalCode: selectedBusiness.postalCode,
                companyDescription: selectedBusiness.companyDescription,
                featured: selectedBusiness.featured,
                category: selectedBusiness.category,
            };
        } else if (addType === "gas_station" || businessType === "gas_station") {
            business = {
                name: selectedBusiness.name,
                address: selectedBusiness.address,
                city: selectedBusiness.city,
                province: selectedBusiness.province,
                phone: selectedBusiness.phone,
                location: {
                    lat: selectedBusiness.location?.lat || "",
                    long: selectedBusiness.location?.long || "",
                },
            };
        }

        try {
            if (mode === "add") {
                if (addType === "indigenous_business") {
                    await ResourcesApi.addIndigenousBusiness(business);
                } else if (addType === "gas_station") {
                    await ResourcesApi.addGasStation(business);
                }
            } else if (mode === "update") {
                if (businessType === "indigenous_business") {
                    await ResourcesApi.updateIndigenousBusiness(selectedBusiness.id, business);
                } else if (businessType === "gas_station") {
                    await ResourcesApi.updateGasStation(selectedBusiness.id, business);
                }
            }

            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                setSelectedBusiness({});
            }, 4000);
        } catch (error) {
            setError(error.response?.data?.message || "An error occurred. Please try again.");
            window.scrollTo(0, 0);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
    };

    const handleAddTypeChange = (e) => {
        setAddType(e.target.value);
    };

    if (!isAdmin) {
        return <p className="input-error main">Access Denied, admins only</p>;
    }

    return (
        <main className="main container">
            <h1 className="title">Search and Manage Businesses</h1>
            <div className="selection">
                <button className="selection__button" value="add" onClick={handleModeChange}>
                    Add New Entry
                </button>
                <button className="selection__button" value="update" onClick={handleModeChange}>
                    Update Existing Entry
                </button>
            </div>

            {mode === "update" && (
                <UpdateAddSearch
                    searchTerm={searchTerm}
                    onSearchTermChange={handleSearchTermChange}
                    businessType={businessType}
                    onBusinessTypeChange={handleBusinessTypeChange}
                    results={results}
                    onSelect={handleSelect}
                    onExit={handleExit}
                    showDropdown={showDropdown}
                    searchRef={searchRef}
                />
            )}

            {error && <p className="input-error error-container ">{error}</p>}

            {mode === "add" && (
                <UpdateAddType addType={addType} onAddTypeChange={handleAddTypeChange} />
            )}

            {(mode === "update" || mode === "add") && (
                <UpdateAddForm
                    mode={mode}
                    businessType={businessType}
                    addType={addType}
                    selectedBusiness={selectedBusiness}
                    setSelectedBusiness={setSelectedBusiness}
                    onSubmit={handleFormSubmit}
                    onDelete={handleDelete}
                />
            )}

            {success && (
                <div className="success-overlay">
                    <p className="success-overlay__font">
                        Business was successfully updated/added!
                    </p>
                </div>
            )}
        </main>
    );
}

export default UpdateBiz;
