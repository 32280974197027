import React, { useEffect, useState } from "react";
import "./HomeAbout.scss";
import { Link } from "react-router-dom";
import useIntersectionObserver from "../Hooks/IntersectionObserver";
import axios from "axios";
import ArticleCard from "../../Components/ArticleCard/ArticleCard";

function HomeAbout() {
    const [ref1, entries1] = useIntersectionObserver(0.3);
    const [ref2, entries2] = useIntersectionObserver(0.3);
    const [ref3, entries3] = useIntersectionObserver(0.3);
    const [articles, setArticles] = useState([]);
    const [randomArticles, setRandomArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/articles`
                );
                setArticles(response.data.articles);
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        };

        fetchArticles();
    }, []);

    useEffect(() => {
        if (articles.length > 0) {
            const shuffled = [...articles].sort(() => Math.random() - 0.5);
            setRandomArticles(shuffled.slice(0, 4));
        }
    }, [articles]);

    return (
        <section className="home-about-container">
            <section
                className={`home-about-card ${
                    entries1.some((entry) => entry.isIntersecting) ? "visible" : ""
                }`}
                ref={ref1}
            >
                <h2 className="home-about-card__header">
                    Welcome to RezBuddy, your ultimate navigator to First Nation-owned gas stations
                    and businesses across Canada!
                </h2>
                <p className="home-about-card__body">
                    Whether you're looking to save on tax-free fuel with your Indian Status or eager
                    to support indigenous enterprises, RezBuddy makes it seamless with a
                    user-friendly map, dotted with gas stations just for you. Dive into a world of
                    convenience where you can easily find, save, and get directions to the nearest
                    First Nation gas stations near you. Search manually or use your current
                    location, either way, RezBuddy ensures you're never far from a great deal or a
                    supporting community.
                </p>
            </section>
            <section
                className={`home-about-card ${
                    entries2.some((entry) => entry.isIntersecting) ? "visible" : ""
                }`}
                ref={ref2}
            >
                <h2 className="home-about-card__header">
                    Beyond fuel, RezBuddy is a vibrant marketplace for discovering First Nation
                    businesses in sectors like retail, food, services, and tourism.
                </h2>
                <p className="home-about-card__body">
                    Whether you're searching for unique local crafts, delicious indigenous cuisine,
                    or reliable services, our extensive directory is tailored to enhance your access
                    and engagement. Use our quick search feature to explore new favorites and
                    connect with the heart of Canada's indigenous communities. Our First Nation
                    business directory, lists all government-registered First Nation businesses, and
                    we are accepting any first nation business to list in our directory for
                    <span className="bold"> FREE</span>. Please reachout to us via our
                    <Link to="/contact-us" className="home-about-card__link">
                        Contact Us
                    </Link>
                    page if you're interested.
                </p>
                <p className="home-about-card__body">
                    With RezBuddy, every transaction is a step towards empowering First Nation
                    businesses and cultivating stronger, sustainable communities.
                </p>
            </section>
            <section className="homepage-articles">
                <Link to="/articles">
                    <h2 className="homepage-articles__header">Check Out Our Articles</h2>
                </Link>
                <div className="homepage-articles__container">
                    {randomArticles.length > 0 ? (
                        randomArticles.map((article) => (
                            <ArticleCard key={article.id} article={article} />
                        ))
                    ) : (
                        <p className="homepage-articles__none">Loading...</p>
                    )}
                </div>
                <Link to="/articles">
                    <h3 className="homepage-articles__link">View All Articles</h3>
                </Link>
            </section>
            <section
                className={`home-about-card ${
                    entries3.some((entry) => entry.isIntersecting) ? "visible" : ""
                }`}
                ref={ref3}
            >
                <h2 className="home-about-card__header">Connect With Us</h2>
                <p className="about-container__body">
                    <strong>RezBuddy </strong>is more than just a search tool; it's a bridge between
                    cultures, economies, and individuals striving for a better, more connected
                    Canada. If you encounter any discrepancies or wish to learn more about how we
                    can support your community,
                    <Link to="/contact-us" className="home-about-card__link">
                        Contact Us.
                    </Link>
                    Together, let's drive towards a future where every fuel purchase supports
                    community growth and affordable living.
                </p>
            </section>
        </section>
    );
}

export default HomeAbout;
