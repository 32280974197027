import axios from "axios";

class ResourcesApi {
    constructor() {
        this.serverUrl = process.env.REACT_APP_BACKEND_URL;
    }

    async getInitialBusinesses() {
        const response = await axios.get(`${this.serverUrl}/api/business/initial`);
        return response.data;
    }

    async getBusinessesByCategory(category, page = 1, pageSize = 10) {
        const response = await axios.get(`${this.serverUrl}/api/business/category/${category}`, {
            params: { page, pageSize },
        });
        return response.data;
    }

    async searchBusinesses(term) {
        const response = await axios.get(`${this.serverUrl}/api/business/search/${term}`);
        return response.data;
    }

    async searchGasStations(term) {
        const response = await axios.get(`${this.serverUrl}/api/markers/${term}`);
        return response.data;
    }

    async getBusinessesByLetter(letter, page = 1, pageSize = 10) {
        const response = await axios.get(`${this.serverUrl}/api/business/letter/${letter}`, {
            params: { page, pageSize },
        });
        return response.data;
    }

    async getBusinessById(businessId) {
        const response = await axios.get(`${this.serverUrl}/api/business/${businessId}`);
        return response.data;
    }

    async addIndigenousBusiness(business) {
        const token = localStorage.getItem("token");
        const response = await axios.post(`${this.serverUrl}/api/business/indigenous`, business, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response;
    }

    async updateIndigenousBusiness(id, business) {
        const token = localStorage.getItem("token");
        const response = await axios.put(
            `${this.serverUrl}/api/business/indigenous/${id}`,
            business,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response;
    }

    async deleteIndigenousBusiness(businessId) {
        const token = localStorage.getItem("token");
        const response = await axios.delete(`${this.serverUrl}/api/business/${businessId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response;
    }

    async addGasStation(gasStation) {
        const token = localStorage.getItem("token");
        const response = await axios.post(`${this.serverUrl}/api/markers/add`, gasStation, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response;
    }

    async updateGasStation(gasStation) {
        const token = localStorage.getItem("token");
        const response = await axios.put(
            `${this.serverUrl}/api/markers/${gasStation.id}`,
            gasStation,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response;
    }

    async deleteGasStation(gasStationId) {
        const token = localStorage.getItem("token");
        const response = await axios.delete(`${this.serverUrl}/api/markers/${gasStationId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response;
    }

    async getUserDetails() {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${this.serverUrl}/api/users/details`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    }
}

export default new ResourcesApi();
